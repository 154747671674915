.hdr {
	width: 92%;
    height: 56px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    position: sticky;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 18%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 12px;
    z-index: 9;
    border-radius: 8px;
    margin: 0 auto;
}
.hdr svg {
	padding: 0 8px;
}
.fest {
	width: 100%;
	margin-top: 22px;
}
.fest .fest-main {
	width: 92%;
	margin: 0 auto;
}
.fest .fest-main img {
	width: 100%;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	min-width: 100%;
	max-width: 100%;
}

.shareButton {
	height: 56px;
	border: 0;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: 0;
	cursor: pointer;
}

.rmts-logo {
	width: 48px;
	padding-left: 8px;
}

@media screen and (min-width: 768px) {
	.hdr {
		width: 100%;
	}
}
