body {
	margin: 0;
	padding: 0;
}

.snackbar {
	width: 100%;
	height: 56px;
	position: fixed;
	bottom: 0;
	animation: showSnackBar 300ms linear;
}

.snackbar .snackbar-main {
	width: 100%;
	height: 56px;
	display: flex;
	align-items: center;
	padding: 0 14px;
	background: #282c3f;
	color: #fff;
	font-family: var(--primary-font);
	font-weight: normal;
	letter-spacing: 1px;
	font-size: 14px;
	margin: 0 auto;
}

@keyframes showSnackBar {
	0% {
		bottom: -56px;
	}
	100% {
		bottom: 0;
	}
}

.snackbar:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background: white;
	opacity: 0.3;
	height: 100%;
	width: 100%;
	transform: skewX(-45deg);
	background: linear-gradient(to right, white, white 20px, transparent 20px);
	transform-origin: left bottom;
	animation: shine 10s ease-in infinite;
}

@keyframes shine {
	0% {
		transform: skewX(-45deg) translateX(-100%);
	}
	10% {
		transform: skewX(-45deg) translateX(100%);
	}
	100% {
		transform: skewX(-45deg) translateX(100%);
	}
}
