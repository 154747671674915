.searchSkeleton {
	display: block;
	width: 100%;
	height: 100%;
}

.searchSkeleton .searchSkeleton-main {
	padding: 0 18px;
}

.searchSkeleton .searchSkeleton-main .ss-text {
	font-family: var(--primary-font);
	padding-top: 26px;
	font-size: 13px;
}

.searchSkeleton .searchSkeleton-main .ss {
	width: 100%;
	display: block;
	margin: 18px 0;
}

.searchSkeleton .searchSkeleton-main .ss .map-image {
	width: 100%;
	height: 122px;
	background: rgba(0, 0, 0, 0.08);
	mix-blend-mode: normal;
	border-radius: 4px;
}

.searchSkeleton .searchSkeleton-main .ss .ss-info {
	width: 100%;
	display: flex;
	align-items: center;
	height: 56px;
	margin-top: 10px;
}

.searchSkeleton .searchSkeleton-main .ss .ss-info .ss-busNo {
	width: 54px;
	height: 54px;
	background: rgba(0, 0, 0, 0.08);
	border-radius: 2px;
}

.searchSkeleton .searchSkeleton-main .ss .ss-info .ss-data {
	width: 80%;
	height: 48px;
	display: block;
	margin-left: 12px;
}

.searchSkeleton .searchSkeleton-main .ss .ss-info .ss-data .line-one {
	width: 90%;
	height: 12px;
	background: rgba(0, 0, 0, 0.08);
	border-radius: 2px;
	animation: inc 1s linear infinite;
}

@keyframes inc {
	0% {
		width: 0;
	}
	100% {
		opacity: 0;
	}
}

.searchSkeleton .searchSkeleton-main .ss .ss-info .ss-data .line-two {
	width: 70%;
	height: 12px;
	background: rgba(0, 0, 0, 0.08);
	border-radius: 2px;
	margin-top: 12px;
	animation: inc 1s linear infinite;
}

.search {
	width: 100%;
	height: 100%;
	display: block;
	margin: 14px 0;
	z-index: 999;
}

.search-main {
	padding: 12px 18px;
}

.search .search-main .searchbar {
	width: 100%;
	height: 56px;
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	align-items: center;
}

label {
	width: 100%;
	height: 56px;
	background: #ffffff;
	border: 2px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	align-items: center;
}

.search .search-main .search-input {
	width: 80%;
	height: 50px;
	border: 0;
	background: #fff;
	outline: 0;
	padding: 0 12px;
	font-family: var(--primary-font);
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0.02em;
}

.search .search-main .search-button {
	width: 30%;
	height: 56px;
	background: var(--primary-color);
	border: 0;
	outline: 0;
	padding: 8px 12px;
	font-family: var(--primary-font);
	font-size: 14px;
	color: #fff;
	letter-spacing: 0.02em;
	font-weight: bold;
}

.search .search-main .search-button:hover {
	background: rgba(0, 0, 0, 0.7);
	transition: all 300ms linear;
}

.search .search-main .search-button:focus {
	background: rgba(0, 0, 0, 0.7);
	transition: all 300ms linear;
}

.search-result {
	width: 100%;
	height: 100%;
	display: block;
	margin: 14px 0;
}

.search-result .search-result-main {
	padding: 0 18px;
}

.location {
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
		0px 4px 8px rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	height: 100%;
	display: block;
	margin-top: 32px;
}
.location .map {
	width: 100%;
	position: relative;
}

.location .map img {
	width: 100%;
}

.location .map .direction {
	position: absolute;
	left: 12px;
	top: 12px;
	padding: 8px 12px;
	color: var(--primary-color);
	background: #fff;
	border: 0;
	border-radius: 4px;
	font-family: var(--primary-font);
	font-weight: 600;
	letter-spacing: 0.04em;
	text-transform: capitalize;
	display: flex;
	align-items: center;
}

.location .map .refresh {
	position: absolute;
	right: 12px;
	top: 12px;
	padding: 8px 12px;
	color: #fff;
	background: var(--primary-color);
	border: 0;
	border-radius: 4px;
	font-family: var(--primary-font);
	font-weight: 600;
	letter-spacing: 0.04em;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	text-transform: uppercase;
}

.refresh.loading {
	width: 56px;
	text-transform: lowercase;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.refresh.loading .dot-one {
	width: 8px;
	height: 8px;
	background: #fff;
	border-radius: 50%;
	animation: dot 750ms linear infinite;
}

@keyframes dot {
	50% {
		transform: scale(1.5);
		opacity: 0.8;
	}
}

.refresh.loading .dot-two {
	width: 8px;
	height: 8px;
	margin-left: 8px;
	background: #fff;
	border-radius: 50%;
	animation: dot 750ms linear infinite;
	animation-delay: 50ms;
}

.refresh.loading .dot-three {
	width: 8px;
	height: 8px;
	margin-left: 8px;
	background: #fff;
	border-radius: 50%;
	animation: dot 750ms linear infinite;
	animation-delay: 150ms;
}

.timeWait {
	width: 56px;
	text-transform: lowercase;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 12px;
	top: 12px;
	padding: 8px 12px;
	color: #fff;
	background: var(--primary-color);
	border: 0;
	border-radius: 4px;
	font-family: Roboto;
	letter-spacing: 0.04em;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.location .desc {
	width: 100%;
}

.location .desc .desc-main {
	padding: 12px 15px;
	display: flex;
	align-items: center;
}

.location .desc .desc-main .busNo {
	width: 52px;
	height: 52px;
	color: #fff;
	background: var(--primary-color);
	display: flex;
	justify-content: center;
	align-items: center;
	letter-spacing: 0.04em;
	font-family: var(--primary-font);
	font-weight: bold;
	border-radius: 4px;
}

.location .desc .desc-main .sd {
	display: block;
	height: 52px;
	margin-left: 16px;
	margin-top: 11px;
}

.location .desc .desc-main .t {
	display: block;
	width: 100%;
	font-family: var(--primary-font);
	font-weight: bold;
	color: #000;
	font-size: 14px;
}

.location .desc .desc-main .status {
	font-family: var(--primary-font);
	font-weight: 600;
	font-style: normal;
	color: rgba(0, 0, 0, 0.78);
	font-size: 11px;
	margin-top: 4px;
}

p {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

.downloadBannerImage {
	width: 94%;
	max-width: 94%;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	margin: 22px 0;
}

.feedback-area {
	padding: 12px 18px;
}

.help-feedback {
	width: 100%;
	background: #fff;
	border-radius: 4px;
	box-shadow: 0px 0px 1px rgb(0 0 0 / 4%), 0px 0px 2px rgb(0 0 0 / 6%),
		0px 4px 8px rgb(0 0 0 / 4%);
}

.help-feedback-main {
	padding: 12px 15px;
	max-height: 72px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.help-feedback-main > .title-and-desc > .title {
	font-weight: bold;
	font-family: var(--primary-font);
	color: var(--primary-color);
	font-size: 14px;
}

.help-feedback-main > .title-and-desc > .desc {
	font-family: var(--primary-font);
	font-size: 12px;
}

.public-note {
	width: 100%;
	background: #ff980063;
	border-radius: 4px;
	margin-top: 12px;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.public-note > .public-note-main {
	padding: 12px;
}

.public-note > .public-note-main > .title {
	font-weight: bold;
	font-family: var(--primary-font);
	color: var(--primary-color);
	font-size: 14px;
}

.public-note > .public-note-main > .desc {
	padding-top: 8px;
	font-weight: bold;
	font-family: var(--primary-font);
	color: #2f2e41db;
	font-size: 12px;
}

.auto-refresh {
	width: 100%;
	height: 56px;
	background: #fff;
	border-radius: 4px;
	margin-top: 12px;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.auto-refresh > .auto-refresh-main {
	padding: 0 12px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.auto-refresh > .auto-refresh-main > .title {
	font-weight: bold;
	font-family: var(--primary-font);
	color: var(--primary-color);
	font-size: 14px;
}

.auto-refresh > .auto-refresh-main > .switch {
	width: 68px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.auto-refresh > .auto-refresh-main > .switch > .path {
	width: 36px;
	height: 11px;
	background: #2f2e415e;
	border-radius: 50px;
}

.auto-refresh > .auto-refresh-main > .switch > .thumb {
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background: var(--primary-color);
	position: relative;
	left: 22px;
	transition: all 200ms;
}

.auto-refresh > .auto-refresh-main > .switch[data-checked="false"] > .thumb {
	left: 22px;
	background: #9e9e9e;
}

.auto-refresh > .auto-refresh-main > .switch[data-checked="true"] > .thumb {
	left: 36px;
	background: var(--primary-color);
}
