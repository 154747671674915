.homeSkeleton {
	width: 100%;
	height: 100%;
}

.homeSkeleton .homeSkeleton-main {
	padding: 12px 18px;
}

.homeSkeleton .homeSkeleton-main .title {
	width: calc(100% / 4);
	height: 12px;
	border-radius: 4px;
	background: rgba(0, 0, 0, 0.08);
	margin: 10px 0;
	animation-delay: 1s;
	animation: skale 300ms linear;
}

.homeSkeleton .homeSkeleton-main .boxes {
	display: flex;
}

.homeSkeleton .homeSkeleton-main .boxes .box {
	width: 46px;
	height: 46px;
	background: rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	margin-top: 12px;
}

.homeSkeleton .homeSkeleton-main .boxes .box + .box {
	margin-left: 18px;
}

.homeSkeleton .homeSkeleton-main .search,
.a {
	margin-top: 42px;
}

.homeSkeleton .homeSkeleton-main .search .searchbar {
	width: 100%;
	height: 52px;
	background: rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	margin-top: 16px;
	overflow: hidden;
	position: relative;
}

.homeSkeleton .homeSkeleton-main .search .searchbar .seachbar-interior:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	opacity: 0.3;
	height: 100%;
	width: 100%;
	transform: skewX(-45deg);
	background: linear-gradient(
		to right,
		rgba(0, 0, 0, 0.08),
		rgba(0, 0, 0, 0.3) 20px,
		transparent 20px
	);
	transform-origin: left bottom;
	animation: shine 5s ease-in infinite;
}

.homeSkeleton .homeSkeleton-main .ad {
	margin-top: 42px;
}

.homeSkeleton .homeSkeleton-main .ad .ad-banner {
	width: 100%;
	height: 124px;
	background: rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	margin-top: 16px;
	overflow: hidden;
	position: relative;
}

.homeSkeleton .homeSkeleton-main .ad .ad-banner .ad-banner-interior:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	opacity: 0.3;
	height: 100%;
	width: 100%;
	transform: skewX(-45deg);
	background: linear-gradient(
		to right,
		rgba(0, 0, 0, 0.08),
		rgba(0, 0, 0, 0.3) 20px,
		transparent 20px
	);
	transform-origin: left bottom;
	animation: shine 5s ease-in infinite;
}

@keyframes shine {
	0% {
		transform: skewX(-45deg) translateX(-100%);
	}

	10% {
		transform: skewX(-45deg) translateX(100%);
	}

	100% {
		transform: skewX(-45deg) translateX(100%);
	}
}

@keyframes skale {
	0% {
		width: 0;
	}
}

.home {
	width: 100%;
	height: 100%;
	display: block;
}

.home .home-main {
	padding: 12px 18px;
}

.home .home-main .pop-buses {
	width: 100%;
	height: 100%;
}

.home .home-main .pop-buses .title {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	font-weight: 800;
	line-height: 14px;
	letter-spacing: 0.02em;
	padding: 12px 0;
	font-family: var(--primary-font);
}

.home .home-main .pop-buses .pop-buses-bus {
	width: 100%;
	height: 56px;
	display: flex;
	align-items: center;
}

.home .home-main .pop-buses .pop-buses-bus a + a {
	margin-left: 12px;
}

.home .home-main .pop-buses .pop-buses-bus .pop-bus-no {
	width: 52px;
	height: 52px;
	color: #fff;
	background: var(--primary-color);
	display: flex;
	justify-content: center;
	align-items: center;
	letter-spacing: 0.04em;
	font-family: var(--primary-font);
	font-weight: bold;
	border-radius: 4px;
}

.home .home-main .pop-buses .pop-buses-bus .pop-bus-no + .pop-bus-no {
	margin-left: 12px;
}

.weather {
	width: 100%;
	height: 167px;
	background: rgba(87, 90, 137, 0.1);
	border-radius: 4px 4px 0px 0px;
	margin-top: 16px;
}

.weather .weather-main {
	padding: 18px;
}

.weather .weather-main .title {
	font-family: var(--primary-font);
	font-style: normal;
	font-size: 13px;
	line-height: 14px;
	letter-spacing: 0.02em;
	color: #000;
	font-weight: bold;
}

.weather .weather-main .temp {
	display: flex;
	height: 112px;
	margin-top: 0;
	align-items: center;
	justify-content: space-between;
}

.weather .weather-main .temp .temp-celcius {
	font-family: var(--primary-font);
	font-weight: bold;
	font-size: 52px;
	line-height: 68px;
	/* identical to box height */

	letter-spacing: 0.02em;

	color: #000000;
}

.weather .weather-main .imgCel {
	display: flex;
	align-items: center;
}

.weather .weather-main .temp img {
	width: 72px;
}

.addToHomeScreen {
	width: 100%;
	height: 152px;
	background: linear-gradient(
		180deg,
		#f2f2f2 12.87%,
		rgba(255, 255, 255, 0) 112.87%
	);
	position: relative;
	border-radius: 4px 4px 0px 0px;
	margin-top: 16px;
}

.addToHomeScreen .addToHomeScreen-main {
	padding: 18px;
	font-family: var(--primary-font);
}

.addToHomeScreen .addToHomeScreen-main .title {
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.02em;
	color: #000;
	font-size: 16px;
}

.addToHomeScreen .addToHomeScreen-main .web-desc {
	color: #000;
	font-size: 4px;
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.02em;
	padding-top: 6px;
	color: #000;
}

.addToHomeScreen .addToHomeScreen-main .bar {
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.addToHomeScreen .addToHomeScreen-main .bar .addToHomeScreenButton {
	position: relative;
	padding: 14px 28px;
	border: 0;
	background: var(--primary-color);
	color: #fff;
	font-family: var(--primary-font);
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 2px;
}

.aboutBar {
	width: 100%;
	margin-top: 18px;
	height: 56px;
}

.aboutBar .aboutBar-main {
	padding: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: var(--primary-font);
	font-size: 13px;
	line-height: 17px;
	letter-spacing: 0.04em;
	color: rgba(0, 0, 0, 0.75);
}

.openAbout {
	width: 100%;
	background: #f5f5f6;
	margin-top: 8px;
}

.openAbout .openAbout-main {
	padding: 18px;
	font-family: var(--primary-font);
}

.openAbout .openAbout-main .headingSection.f {
	padding: 0;
}

.openAbout .openAbout-main .headingSection {
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.04em;
	color: #282c3f;
	padding-top: 24px;
}

.openAbout .openAbout-main .descSection {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 17px;
	letter-spacing: 0.04em;
	color: #282c3f;
	padding-top: 13px;
}

.ad {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	font-weight: 800;
	line-height: 14px;
	letter-spacing: 0.02em;
	font-family: var(--primary-font);
	margin-top: 22px;
}

.ad div img {
	width: 100%;
	min-width: 100%;
	margin-top: 10px;
}

.offline {
	width: 100%;
	height: 100%;
	display: block;
}

.offline .offline-main-part-f {
	width: 100%;
	height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.offline .offline-main-part-s {
	width: 100%;
	height: 100%;
	display: block;
	text-align: center;
}

.offline .offline-main-part-s p {
	font-family: var(--primary-font);
	width: 100%;
	font-size: 16px;
	color: #000;
	font-weight: bold;
}

.offline .offline-main-part-s .dark {
	width: 100%;
	font-family: var(--primary-font);
	font-size: 12px;
	color: #000000b0;
}

.offline .offline-main-part-s div .reload {
	margin-top: 36px;
	margin: 0 auto;
	padding: 12px 24px;
	font-family: var(--primary-font);
	color: var(--primary-color);
	font-weight: bold;
	outline: 0;
	text-transform: uppercase;
	border-radius: 4px;
	border: 2px solid var(--primary-color);
	background: #fff;
}

.offline .offline-main-part-s div .reload:hover {
	background: #8d3a7f1f;
}

.offline .offline-main-part-s div .reload:focus {
	background: #8d3a7f1f;
}

.home-nav {
	overflow-y: hidden;
	overflow-x: scroll;
	display: flex;
	padding-bottom: 12px;
}

.home-card {
	margin-left: 18px;
}

.share_banner {
	width: 100%;
	height: 52px;
	background: linear-gradient(
		to right,
		rgb(138, 35, 135),
		rgb(233, 64, 87),
		rgb(242, 113, 33)
	);
	border-radius: 4px;
	color: #fff;
	font-family: var(--primary-font);
	font-size: 16px;
	display: flex;
	align-items: center;
	font-weight: normal;
}

.all-buses {
	width: 100%;
	border-radius: 4px;
	background: var(--primary-color);
	margin-bottom: 12px;
	transition: all 400ms linear alternate;
}
.all-buses:hover {
	transform: scale(1.02);
}
.all-buses .all-buses-main {
	padding: 12px 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.all-buses .all-buses-main .all-buses-title {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	font-weight: 800;
	line-height: 14px;
	letter-spacing: 0.02em;
	font-family: var(--primary-font);
	color: #ffffff;
}

@media screen and (max-width: 362px) {
	.home .home-main .pop-buses .pop-buses-bus .pop-bus-no {
		width: 46px;
		height: 46px;
	}
}

@media screen and (max-width: 305px) {
	.home .home-main .pop-buses {
		display: none;
	}

	.home .home-main .pop-buses .pop-buses-bus .pop-bus-no {
		display: none;
	}
}
