.bus-info-card {
	width: 90%;
	margin: 0 auto;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.23);
	box-sizing: border-box;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
		0px 0px 1px rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	padding: 20px;
}

.bus-info-card-data {
	width: 100%;
	height: 72px;
	display: flex;
	align-items: center;
}

.bus-info-card-data-bus-no {
	font-family: var(--primary-font);
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	line-height: 62px;
	letter-spacing: 0.02em;
	color: #3c4043;
	padding: 0 18px;
}

.bus-info-card-data-data {
	width: 100%;
	overflow: hidden;
	margin: 0 12px;
}

.bus-info-source-dest {
	width: 100%;
	display: flex;
	font-family: var(--primary-font);
	font-size: 13px;
}

.bus-info-extra-info {
	font-family: var(--primary-font);
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 14px;
	letter-spacing: 0.02em;
	color: #3f3d56;
}

.bus-info-to {
	width: 120px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: bold;
	text-align: center;
}

.bus-info-exchange {
	width: 36px;
	display: flex;
	justify-content: center;
}

.bus-info-from {
	width: 120px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: bold;
	text-align: center;
}

.bus-info-extra-info {
	padding-top: 4px;
}

.bus-info-data {
	width: 100%;
	display: block;
}

.bus-info-data-col {
	width: 100%;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: var(--primary-font);
	color: var(--primary-color);
	font-size: 13px;
}

.bus-info-data-col:last-child {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.bus-info-loading {
	width: 100%;
	display: flex;
	justify-content: center;
}

.circular-loader {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	border: 2px solid var(--primary-color);
	border-bottom: 2px solid transparent;
	animation: cir 600ms linear infinite;
}

@keyframes cir {
	100% {
		transform: rotate(360deg);
	}
}
